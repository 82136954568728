import { template as template_f95874c2d81542d48e43441761cb54f1 } from "@ember/template-compiler";
const EmptyState = template_f95874c2d81542d48e43441761cb54f1(`
  <div class="empty-state-container">
    <div class="empty-state">
      <span data-test-title class="empty-state-title">{{@title}}</span>
      <div class="empty-state-body">
        <p data-test-body>{{@body}}</p>
      </div>
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default EmptyState;
