import { template as template_dfb8c689aa09427e89da8a10644ed170 } from "@ember/template-compiler";
const FKText = template_dfb8c689aa09427e89da8a10644ed170(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
