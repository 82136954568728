import { template as template_104cf739e171468eba717da099183d17 } from "@ember/template-compiler";
const FKLabel = template_104cf739e171468eba717da099183d17(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
